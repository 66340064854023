.messages{
  height: 50%;
  margin-bottom: 80%;
}
.message{

}

.message-from {
    position: relative;
    margin-left: 20px;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 25px;
    background-color: rgba(228, 235, 240, 0.32);
    width: 200px;
    min-height: 20px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #d8e2e8;
    border-radius: 10px;
    word-break: break-word;
}

.message-to {
    position: relative;
    margin-bottom: 5px;
    margin-left: calc(100% - 240px);
    padding: 10px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 25px;
    background-color: rgba(85, 200, 255, 0.28);
    width: 200px;
    min-height: 20px;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #d8e2e8;
    border-radius: 10px;
    word-break: break-word;
}

.message-content {
    padding: 0;
    margin: 0;
}

.message-timestamp-right {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    right: 5px;
}

.message-timestamp-left {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    left: 5px;
}

.message-blue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #A8DDFD;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.message-blue:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #97C6E3;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
}

.message-orange:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 15px solid #f8e896;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
}

.message-orange:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 17px solid #dfd087;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    bottom: -1px;
    right: -17px;
}
